.mention {
    color: rgb(85, 10, 246);
    background-color: #a3a3a3;
    /* color: #4a85bb; */
    text-decoration: none;
}

.mentionSuggestions {
    border-top: 2px solid rgb(127, 86, 217);
    background: #121212;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    margin: -16px;
}

.mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
}

.mentionSuggestionsEntry {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
}

.mentionSuggestionsEntry:active {
    background-color: #3e3e3e;
}

.mentionSuggestionsEntryFocused {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
    background-color: #6f6f6f;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}