html {
  box-sizing: border-box;
}

html,
body,
#root {
  padding: 0px !important;
  margin: 0px !important;
  /* height: 100vh; */
  /* width: 100vw; */
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  /* overflow-y: hidden; */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #212529;
}

.logo {
  max-width: 80px;
}

.logo-large {
  width: 100%;
  max-width: 200px;
}

.dark {
  /* background-color: #212529; */
}

.titlecolor {
  background-color: hsla(0, 0%, 100%, .1);
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #7f56d9;
}

.titlebar {
  /* background-color: hsla(0, 0%, 100%, .1); */
  background-color: #393b3f;
  /* rgba(0, 76, 153, .15) */
  padding-bottom: 0.4rem !important;
  padding-top: 0.4rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  height: 64px;
  width: 100%;
}

.imdisco {
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 2em;
  background-image: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  display: inline-block;
  margin: 0;
  line-height: 1.2; */
}

.tagline {
  /* font-family: Inter, sans-serif; */
  color: rgb(180, 180, 180);
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
  /* margin-left: 1%; */
  /* margin-bottom: 15px; */
  font-size: .7em;
}

.imdisco-text {
  /* background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

div.ReactCrop__drag-handle,
div.ReactCrop__drag-handle::after {
  width: 40px;
  height: 40px;
}

div.ReactCrop .ord-nw {
  margin-top: -20px;
  margin-left: -20px;
}

div.ReactCrop .ord-ne {
  margin-top: -20px;
  margin-right: -20px;
}

div.ReactCrop .ord-sw {
  margin-bottom: -20px;
  margin-left: -20px;
}

div.ReactCrop .ord-se {
  margin-bottom: -20px;
  margin-right: -20px;
}

div.infinite-scroll-component__outerdiv,
div.infinite-scroll-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.remix-button {
  /* background: #4e54c8;
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  color: #fff;
  font-weight: bold;
  border: 2px solid #888;
  --bs-btn-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  --bs-btn-hover-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  outline: none;
  box-shadow: none; */
}

.modal-noborder:focus-visible {
  outline: none;
  box-shadow: none;
}

.rcrop {
  /* max-height: 80%; */
  max-height: 512px;
}

.fleximg {
  display: block;
  width: 100%;
  height: 100%;
  /* max-height: "512px"; */
  object-fit: contain;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  /* user-select: none !important; */
}

.flexaspectimg {
  width: auto;
  height: auto;
  max-width: 100%;
  /* max-height: 100%; */
  max-height: "512px";
  object-fit: contain;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  /* user-select: none !important; */
}

.token_text {
  color: #123456
}

/* 
.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ABABAB;
  background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
} */

/* div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  color: #000;
  height: 200px;
  width: 300px;
  overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  min-height: 140px;
}
 */

.public-DraftEditorPlaceholder-root {
  position: relative;
  /* text-overflow: clip;
   */
  /* margin-right: 5%; */
  /* color: #9e9e9e; */
  /* pointer-events: none; */
}

.public-DraftEditorPlaceholder-inner {
  position: absolute;
  /* text-overflow: clip;
   */
  /* margin-right: 5%; */
  color: #9e9e9e;
  pointer-events: none;
}


/* button code */
button.crop_edit_btn,
button.crop_edit_btn:focus,
button.crop_edit_btn:focus-visible {
  background-color: "#FFF"
}

/* .crop_edit_btn:hover
{
  background: "#FFF"
} */

div.SnackbarContainer-root {
  margin-bottom: 48px;
}

.fab_previous {
  opacity: 0.25;
}

.fab_next {
  /* transparency */
  opacity: 0.5;
}

.fab_active {
  /* background: #4e54c8; */
  /* fallback for old browsers */
  /* background: -webkit-linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  background: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  color: #fff;
  font-weight: bold;
  border: 2px solid #888;
  --bs-btn-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  --bs-btn-hover-bg: linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%);
  outline: none;
  box-shadow: none; */
}

.remix_modal {
  outline: none;
  box-shadow: none;
}

.remix_modal:focus-visible {
  outline: none;
  box-shadow: none;
}

.fabric-canvas {
  height: 100%;
  width: 100%;
}

button.deleteBtn {
  background: none;
  border: none;
  color: #f44;
  font-size: 2em;
  opacity: 1;
  fill-opacity: 1;
}


.deleteBtn svg {
  color: #f44;
  fill-opacity: 1;
  opacity: 1;
  font-size: .8em;
}

button.fab_delete {
  /* background: none; */
  /* border: none; */
  color: #FFF;
  /* color: #f44; */
  /* font-size: 2em; */
  /* opacity: 1; */
  /* fill-opacity: 1; */
}

.btn_animate {
  /* background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite; */
}

.btn_animate_alpha {
  /* background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  opacity: .25;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite; */
}


@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

[style*="--aspect-ratio"]> :first-child {
  width: 100%;
}

[style*="--aspect-ratio"]>img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"]> :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}